<template>
  <v-row class="px-5">
    <v-layout row align-center v-if="juramento===null">
      <v-flex sm12>
        <v-alert  type="error" border="left" elevation="2" outlined>
          {{mensaje}}
        </v-alert>
      </v-flex>
    </v-layout>
    <v-layout row wrap v-else>
      <v-flex lg6 xs6>
        <v-text-field label="Departamento" :value="juramento.departamento" disabled></v-text-field>
      </v-flex>
      <v-flex lg3 xs6>
        <v-text-field label="Fecha" :value="formatDateText(juramento.fecha)" disabled></v-text-field>
      </v-flex>
      <v-flex lg2 xs6>
        <v-text-field label="Hora" :value="juramento.hora" disabled></v-text-field>
      </v-flex>
      <v-flex lg12 xs12>
        <v-text-field label="Lugar / Ubicación" :value="juramento.lugar" disabled></v-text-field>
      </v-flex>
    </v-layout>
  </v-row>
</template>
<script>
import mixinDateTime from "@/mixins/datetime"
export default {
  mixins:[mixinDateTime],
  data() {
    return {
      titulo: "Información del Juramento",
      mensaje: "Todavia no se cuenta con información del Juramento"
    };
  },
  computed: {
    juramento() {
      return this.$store.state.abogado.juramento;
    }
  },
  methods: {
    colorEstado(estado) {
      if (
        estado == "MIGRACION" ||
        estado == "NORMAL" ||
        estado == "CIUDADANIA"
      ) {
        return "primary lighten-3";
      }
      if (estado == "NUEVO") {
        return "primary";
      }
      if (estado == "PENDIENTE") {
        return "warning";
      }
      if (estado == "CONCLUIDO") {
        return "success";
      }
      return "default";
    }
  }
};
</script>

